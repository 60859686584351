export enum DeviceTypeEnum {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
}

export enum PlatformEnum {
  WEB = 'WEB',
}

export enum OperatingSystemEnum {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  MACOS = 'MACOS',
  WINDOWS = 'WINDOWS',
  OTHER = 'OTHER',
}

export interface DeviceInfoModel {
  device: DeviceTypeEnum;
  platform: PlatformEnum;
  os: OperatingSystemEnum;
}
