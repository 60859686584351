export enum PermissionObjectCodeEnum {
  ANONYMOUS = 'anonymous',
  AUTHENTICATED = 'authenticated',
  BACKEND_ACCESS = 'backend.access',
  TOPICS_ADMIN = 'topics.admin',
  COURSES_ADMIN = 'courses.admin',
  DEV_ACCESS = 'dev.access',
  DASHBOARD_ACCESS = 'b2c-dashboard.access',
  B2B_DASHBOARD_ACCESS = 'b2b-dashboard.access',
  EMAILS_ADMIN = 'emails.admin',
  INSTRUCTORS_ADMIN = 'instructors.admin',
  LESSON_NOTES_ADMIN = 'lesson_notes.admin',
  VIDEOS_ADMIN = 'videos.admin',
  PARAMETERS_ADMIN = 'parameters.admin',
  PERMISSIONS_ADMIN = 'permissions.admin',
  USERS_ADMIN = 'users.admin',
  USER_CREDENTIALS_ADMIN = 'users_credentials.admin',
  EXPRESS_LOGIN_ACCESS = 'express_login.access',
  STATISTICS_ACCESS = 'statistics.access',
  REVIEWS_ADMIN = 'reviews.admin',
  SURVEYS_ADMIN = 'surveys.admin',
  ENROLLMENTS_ADMIN = 'enrollments.admin',
  EXTERNAL_WEBHOOKS_ADMIN = 'external.webhooks.admin',
  STRIPE_INVOICES_ADMIN = 'stripe.invoices.admin',
  COUPONS_ADMIN = 'coupons.admin',
  NOTIFICATIONS_ADMIN = 'notifications.admin',
  QUESTIONS_ADMIN = 'questions.admin',
  PAYPAL_ACCESS = 'paypal.access',
  STREAKS_ACCESS = 'streaks.access',
  TEMPLATES_FREE_ACCESS = 'templates-free.access',
  TEMPLATES_SELF_STUDY_ACCESS = 'templates-self-study.access',
  TEMPLATES_FULL_IMMERSION_ACCESS = 'templates-full-immersion.access',
  TEMPLATES_ADMIN = 'templates.admin',
  CASE_STUDY_ACCESS = 'case-study.access',
  B2B_STUDENT_ROLE = 'b2b-student.role',
  FULL_IMMERSION_ROLE = 'full-immersion.role',
  SUBSCRIBER_IMMERSION_ROLE = 'subscribers.role',
  PARTNERSHIP_DEALS_ACCESS = 'partnership-deals.access',
  FINANCIAL_MODEL_FEEDBACK_ACCESS = 'financial-model-feedback.access',
  CV_COVER_LETTER_FEEDBACK_ACCESS = 'cv-cover-letter-feedback.access',
  EMAIL_INBOX_ACCESS = 'email-inbox.access',
  IBISWORLD_PARTNERSHIP_ACCESS = 'ibisworld-partnership.access',
  AI_RESUME_ACCESS = 'ai-resume.access',
  ASK_AN_EXPERT_ACCESS = 'ask-an-expert.access',
  COOKIEYES_ACCESS = 'cookieyes.access',
}
