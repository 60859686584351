export enum SidebarMenuItemIdEnum {
  MY_DASHBOARD = 'my-dashboard',
  ASSIGNED_TRAINING = 'assigned-training',
  MY_LEARNING_JOURNEY = 'my-learning-journey',
  IN_PROGRESS_COURSES = 'in-progress-courses',
  SAVED_CONTENT_AND_NOTES = 'saved-content-and-notes',
  TRANSCRIPTS_AND_CERTIFICATES = 'transcripts-and-certificates',
  CAREERS_AND_ROLES = 'careers-roles',
  GET_CERTIFIED = 'get-certified',
  COURSE_CATALOG = 'course-catalog',
  RESOURCE_CENTER = 'resource-center',
  COMMUNITY = 'community',
  FULL_IMMERSION_TOOLS = 'full-immersion-tools',
  ASK_AN_EXPERT = 'ask-an-expert',
  PARTNERSHIP_DEALS = 'partnership-deals',
  FINANCIAL_MODEL_FEEDBACK = 'financial-model-feedback',
  COVER_LETTER_FEEDBACK = 'cover-letter-feedback',
  HELP_CENTER = 'help-center',
  WHATS_NEW = 'whats-new',
  FOLLOW_CFI_ON_LINKEDIN = 'follow-cfi-on-linkedin',
  ARTICLES = 'articles',
  TEMPLATES = 'templates',
  SKILLS_ASSESSMENTS = 'skills-assessments',
  CASE_STUDY_CHALLENGES = 'case-study-challenges',
  PODCASTS = 'podcasts',
  WEBINARS = 'webinars',
  SIMULATIONS = 'simulations',
}
